<template>
  <b-form-checkbox
      @change="function(val) {selectProducts({productId, val})}"
      v-bind:checked="getCurrState"
  />
</template>

<script>
import {
  BFormCheckbox,
} from 'bootstrap-vue'
import {mapActions, mapState} from "vuex";

export default {
  name: "checkBoxCell",
  components: {
    BFormCheckbox,

  },
  data() {
    return {}
  },
  props: {
    productId: Number,
  },
  methods: {
    ...mapActions('products', ["selectProducts"])
  },
  computed: {
    getCurrState() {
      return this.selectedProductsId.some(prod => prod.id === this.productId);
    },
    ...mapState('products', ["selectedProductsId"]),
  },
}
</script>

<style scoped>


</style>