<template>
  <div class="overflow-hidden" style="background-color: #F8F8FC">
    <b-table
        id="table_detail_size_products"
        class="detail-table"
        :thead-tr-class="'text-nowrap'"
        :fields="fields"
        :items="getListSizes"
    >
      <template #head(menu)="data">
        <div style="width: 100px"></div>
      </template>
      <template #head(name)="data">
        <div style="width: 200px; padding-left: 34px"></div>
      </template>
      <template #head(sebes)="data">
        <div style="width: 150px"></div>
      </template>
      <template #head(storage)="data">
        <div style="width: 80px"></div>
      </template>
      <template #head(preparation)="data">
        <div style="width: 135px"></div>
      </template>
      <template #head(stockmp)="data">
        <div style="width: 50px"></div>
      </template>
      <template #head(wayMp)="data">
        <div style="width: 101px"></div>
      </template>
      <template #head(selectAll)="data">
        <div style="width: 20px; margin-left: 10px"></div>
      </template>
      <template #cell(name)="data">
        <div style="width: 200px; padding-left: 34px">{{ `${data.item.techSize}(${data.item.wbSize})` }}</div>
      </template>
      <template #cell(sebes)="data">
        <div style="width: 200px"></div>
      </template>
      <template #cell(storage)="data">
        <div style="width: 80px">
          {{ data.item.storage }}
        </div>
      </template>
      <template #cell(preparation)="data">
        <div style="width: 80px">
          {{ data.item.preparation }}
        </div>
      </template>
      <template #cell(wayMp)="data">
        <div style="width: 80px">
          {{ data.item.wayMp }}
        </div>
      </template>
      <template #cell(stockmp)="data">
        <div style="width: 80px">
          {{ data.item.stockmp }}
        </div>
      </template>
      <template #cell(menu)="data">
        <div style="width: 18px"></div>
      </template>
      <template #cell(selectAll)="data">
        <div style="width: 32px; margin-left: 10px"></div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {
  BButton,
  BCard,
  BCardText,
  BFormCheckbox,
  BCol,
  BFormInput,
  BFormSelect,
  BLink,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import FInput from "@/components/UI/FInput";
import FInputDef from "@/components/UI/FInputDef";

export default {
  components: {
    FInputDef,
    FInput,
    BCol,
    BRow,
    BFormCheckbox,
    BTable,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    BLink,
    BFormInput,
  },
  name: "TableDetailsSize",
  data() {
    return {
      fields: [
        {
          key: 'selectAll',
        },
        {
          key: 'name',
          label: 'Наименование',
        },
        {
          key: 'sebes',
          label: 'СЕБЕС',
        },
        {
          key: 'storage',
          label: 'На ФФ ',
          sortable: true,
        },
        {
          key: 'preparation',
          label: 'Готовится на МП',
          sortable: true,
        },
        {
          key: 'wayMp',
          label: 'В пути на МП',
          sortable: true,
        },
        {
          key: 'stockmp',
          label: 'на МП',
          sortable: true,
        },
        {
          key: 'menu',
        },
      ],
    }
  },
  props: {
    sizes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {},
  computed: {
    getListSizes() {
      return this.sizes.sort((a, b) => a.wbSize.split('-')[0] - b.wbSize.split('-')[0])
    }
  },
}
</script>

<style scoped>
.table-detail-sizes td {
  padding: 0;
  margin: 0;
}

.hidden-header {
  opacity: 0;
}

.label-size {
  right: 110%;
  top: 7px;
  font-style: italic;
}

.table-support-sizes {
  position: relative;
  top: -45px;
}


</style>

